.wrap {
  background: url("/public/assets/img/bg/breadcumb-bg.jpg");
  background-position: center;
}

.foot {
  background: url("/public/assets/img/bg/footer-bg.png");
}

.lbg {
  background-image: url("/public/assets/img/bg/logo-bg-2.png");
}

.ser {
  background-image: url("/public/assets/img/service/service-2-1.png");
}

.ban {
  background-image: url("/public/assets/img/widget/widget-banner-bg.png");
}

.her {
  background-image: url("/src/assets/workers.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.her2 {
  background-image: url("/public/assets/img/hero/hero_bg_1_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.ht {
  background-image: url("/public/assets/img/bg/header-top1-bg.png");
}

.ct {
  background-image: url("/public/assets/img/bg/counter-bg_1.png");
}

.yy {
  background-image: url("/public/assets/img/bg/why-bg-1.png");
}

.lg-h {
  width: 170px;
}

.activee {
  color: #ea1c24 !important;
}

@media (min-width: 680px) {
  .lg-h {
    width: 140px;
  }
}

@media (min-width: 910px) {
  .lg-h {
    width: 120px;
  }
}

@media (min-width: 960px) {
  .lg-h {
    width: 100px;
  }
}

@media (min-width: 1280px) {
  .lg-h {
    width: 150px;
  }
}

@media (min-width: 1400px) {
  .lg-h {
    width: 250px;
  }
}
